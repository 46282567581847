.container {
  width: 100%;
  /* height: fit-content; */
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  inset: 0;
  z-index: 100;
  /* overflow: hidden; */
}

.popup {
  /* width: 100%; */
  width: fit-content;
  max-width: 90%;
  margin: 0 auto;
  background: #ffffff;
  min-height: 300px;
  height: fit-content;
  max-height: 95vh;
  overflow: auto;
  z-index: 100;
}
