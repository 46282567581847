.candidateProfileWrapper {
  position: relative;
  padding: 30px;
  .cross {
    position: absolute;
    top: 0;
    right: 0;
  }
  .title {
    color: #414141;
    text-align: justify;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 30.8px */
  }
  .basic {
    margin-top: 24px;
    display: flex;
    border-bottom: 1px solid #cfcfcf;
    .avatar {
      display: flex;
      width: fit-content;
      align-items: center;
      flex-direction: column;
      // gap: 20px;
      // margin-left: -20px;
      //   justify-content: center;
      .Available {
        display: flex;
        align-items: center;
        gap: 5px;
        & > span {
          width: 12px;
          height: 12px;
          // display: block;
          border-radius: 50%;
          background: #34a853;
        }
        margin-top: 20px;
        color: #656565;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
      .availability {
        color: #959595;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
      }
    }
    & > div:nth-child(1) {
      width: 206px;
    }
    .nameAndDetails {
      & > div {
        color: #000;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 109.091% */
        margin-bottom: 17px;
        & > span {
          color: #959595;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 120% */
          margin-left: 17px;
        }
        .email {
          color: #4285f4;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 120% */
        }
      }
      & > div:nth-child(1) {
        margin-bottom: 40px;
        font-size: 22px;
      }
    }
  }
  .assessments {
    display: flex;
    margin-top: 28px;
    border-bottom: 1px solid #cfcfcf;

    & > div:nth-child(1) {
      color: #424242;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      text-transform: uppercase;
      width: 206px;
    }
    .allRatings {
      & > div:nth-child(odd) {
        color: #000;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 15px;
      }
      & > div:nth-child(even) {
        color: #878787;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 30px;

        & > span {
          color: #878787;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
  .allSkills {
    display: flex;
    margin-top: 32px;
    & > div:nth-child(1) {
      color: #424242;
      font-family: "Inter";
      width: 206px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      text-transform: uppercase;
    }
    & > div:nth-child(2) {
      flex-grow: 1;
      max-width: 400px;
      & > div:nth-last-child(1) {
        & > div:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
    .skillsDetails {
      .eachItem {
        border-bottom: 1px solid #cfcfcf;
        margin-bottom: 15px;
        padding-bottom: 15px;
        .languageAndProf {
          display: flex;
          justify-content: space-between;
          color: var(--Black, #111113);
          font-family: "Inter";
          margin-bottom: 11px;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 25.2px */
        }
        .frameworks {
          display: flex;
          justify-content: space-between;
          margin-left: 20px;
          > div {
            margin-bottom: 11px;
            color: var(--Black, #111113);

            /* Body */
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }
}
