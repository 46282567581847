.InvitesWrapper {
  margin: 0 100px;
  .homeButton {
    width: fit-content;
    margin-top: 50px;
    & > button {
      color: #3c3c3c;
      font-family: "Inter";
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  .selectTitle {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;
    margin-bottom: 22px;
    & > div:nth-child(1) {
      color: #000;
      font-family: "Inter";
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 44.8px */
    }
    & > div:nth-child(2) {
      width: fit;
      display: flex;
      gap: 24px;
      & > button {
        border-radius: 4px;
        background: #34a853;
        display: flex;
        // width: 167px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        color: var(--white-high, #fff);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;

        /* font/button/large/sentence */
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .allfiltersInvite {
    display: flex;
    gap: 20px;
    width: fit-content;
    border-radius: 5px;
    border: 1px solid #eee;
    background: #fff;
    padding: 24px;
    align-items: center;
    & > div {
      display: flex;
      flex-direction: column;

      & > label {
        color: #81919c;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 4px;
      }
      & > input,
      .myControlClassName {
        border-radius: 3px;
        border: 1px solid #ebebeb;
        background: #fbfbfb;
        outline: none;
        // padding: 7px 11px;
        font-family: "Inter";
        font-size: 14px;
        width: fit-content;
        // max-width: 200px;
        min-width: 150px;
        height: 38px;
      }
      & > input {
        padding: 0px 7px;
      }
      & > input::placeholder {
        color: #81919c;
        font-family: "Inter";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .myMenuClassName {
        font-family: "Inter";
        font-size: 14px;
      }
    }
    & > div {
      & > button {
        border-radius: 3px;
        border: 1.2px solid #25bb7b;
        background: none;
        outline: none;
        color: #25bb7b;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 35px;
        margin-top: 20px;
        padding: 6px 12px;
        cursor: pointer;
      }
    }
    & > div:nth-last-child(1) {
      & > button {
        background: #fff;
        border: none;
        color: black;
      }
    }
  }
}

.sendInvitesWrapper {
  margin-top: 20px;
  background: #f6faff;
  width: fit-content;
  .applicationTableContainer {
    border-collapse: collapse;

    thead {
      border-radius: 2px;
      background: #f6faff;
      padding: 21px;
      th {
        color: var(--secondary-black, #616161);
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        padding: 21px 0;
        text-align: left;
        padding-left: 20px;
      }
      th:last-child {
        padding-right: 21px;
      }
    }

    tbody {
      tr {
        td {
          color: var(--secondary-deep-ocean-100, #2c4761);
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          padding: 20px 0;
          padding-left: 21px;
          .viewProfile {
            border-radius: 4px;
            border: 1.5px solid var(--black-high, #212121);
            color: var(--black-high, #212121);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;

            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            padding: 8px 16px;
            cursor: pointer;
          }
          .selectClass {
            width: 101px;
            border-radius: 4px;
            background: #34a853;
            color: var(--white-high, #fff);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            border: none;
            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            padding: 8px 16px;
            margin-left: 20px;
          }
          .selectedClass {
            width: 101px;
            border-radius: 4px;
            border: 1.5px solid #34a853;
            color: #34a853;
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            background-color: #fff;
            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            padding: 8px 16px;
            margin-left: 20px;
          }
          .removeButton {
            border: none;
            background: red;
            color: #fff;
            padding: 8px 16px;
            font-family: "Inter";
            font-size: 1rem;
            cursor: pointer;
            border-radius: 4px;
          }
        }
        .skills {
          max-width: 200px;
        }
        td:last-child {
          padding-right: 21px;
        }
      }
      tr:nth-child(odd) {
        // border: 1px solid;
        background: #fff;
      }
      tr:nth-child(even) {
        background: #f8f8f8;
      }
    }

    td.viewJd {
      cursor: pointer;
    }

    td.sendInvite {
    }
  }
}

.selectedProfiles {
  position: relative;
  width: fit-content;
  padding: 30px;
  .closeProfiles {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .title {
    color: #000;
    font-family: "Inter";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  .confirmButton {
    border: none;
    background: #34a853;
    // background: #34a853
    color: #fff;
    padding: 16px 48px;
    font-family: "Inter";
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 20px;
  }
   .pagesAndCount {
    width: 93%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;

    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    .itemsPerPage {
      color: var(--black-medium, #909090);
      font-family: "Inter";
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      .selectClass {
        border-radius: 4px;
        border: 1px solid var(--black-disabled-bg, #bcbcbc);
        // padding: 8px;
        // height: fit-content;
        height: 40px;
        margin-left: 10px;
        outline: none;
      }
    }
    .pageNo {
      .pages {
        li {
          button {
            color: var(--black-high, #212121);
            font-family: "Inter" !important;
            font-size: 1rem !important;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
          }
        }
        li:nth-child(1),
        li:nth-last-child(1) {
          border-radius: 4px;
          border: 1px solid var(--black-disabled-bg, #bcbcbc);
          margin: 0 10px;
        }
      }
    }
    .itemCount {
      color: var(--black-medium, #909090);
      /* font/subtitle/s2 */
      font-family: "Inter";
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      & > span {
        color: var(--black-high, #212121);
        /* font/subtitle/s2 */
        font-family: "Inter";
        font-size: 1rem;
        margin-left: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.pagesAndCount {
  width: 93%;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;

  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  .itemsPerPage {
    color: var(--black-medium, #909090);
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    .selectClass {
      border-radius: 4px;
      border: 1px solid var(--black-disabled-bg, #bcbcbc);
      // padding: 8px;
      // height: fit-content;
      height: 40px;
      margin-left: 10px;
      outline: none;
    }
  }
  .pageNo {
    .pages {
      li {
        button {
          color: var(--black-high, #212121);
          font-family: "Inter" !important;
          font-size: 1rem !important;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
        }
      }
      li:nth-child(1),
      li:nth-last-child(1) {
        border-radius: 4px;
        border: 1px solid var(--black-disabled-bg, #bcbcbc);
        margin: 0 10px;
      }
    }
  }
  .itemCount {
    color: var(--black-medium, #909090);
    /* font/subtitle/s2 */
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    & > span {
      color: var(--black-high, #212121);
      /* font/subtitle/s2 */
      font-family: "Inter";
      font-size: 1rem;
      margin-left: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }
}
