.dashboardWrapper {
  margin: 0 100px;
  // overflow: auto;
  .welcome {
    color: #000;
    font-family: "Inter";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
    margin-top: 43px;
  }
  .accountDetails {
    display: flex;
    gap: 25px;
    margin-top: 26px;
    .account {
      border-radius: 2px;
      border: 1px solid #eaeaea;
      background: #fff;
      padding: 16px 30px;
      width: 321px;
      display: flex;
      gap: 12px;
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 52px;
        height: 52px;
      }
      .text {
        & > div:nth-child(1) {
          color: #81919c;
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        & > div:nth-child(2) {
          color: var(--secondary-deep-ocean-100, #2c4761);
          font-family: "Inter";
          font-size: 1.35rem;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 127.273% */
        }
      }
    }
  }
  .toggleAndInput {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 88px;
    .toggleButtons {
      width: fit-content;
      .my-toggle-button-group {
        border-radius: 20px;
        background: #e6e6e6;
        & > button {
          border-radius: 20px;
          padding: 8px 32px;
          border: none;
          outline: none;
          color: #fffffe;
          font-family: "Inter";
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 28px */
        }
      }
    }
    .search {
      display: flex;
      width: 430px;
      height: 40px;
      padding: 10px 13px 10px 13px;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--neutral-light-leafy-green, #e7ede1);
      .inputItem {
        flex: 1;
        input {
          width: 95%;
          border: none;
          outline: none;
          font-size: 1rem;
        }
      }
    }
  }
  .application {
    display: flex;
    gap: 39px;
    color: #3c3c3c;
    font-family: "Inter";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    margin-top: 30px;
    & > div {
      width: fit-content;
      border-bottom: 2px solid #fff;
    }
    .active {
      color: #3b3b3b;
      font-family: "Inter";
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 25.2px */
      border-bottom: 2px solid;
    }
  }
  .pagesAndCount {
    width: 93%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;

    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    .itemsPerPage {
      color: var(--black-medium, #909090);
      font-family: "Inter";
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      .selectClass {
        border-radius: 4px;
        border: 1px solid var(--black-disabled-bg, #bcbcbc);
        // padding: 8px;
        // height: fit-content;
        height: 40px;
        margin-left: 10px;
        outline: none;
      }
    }
    .pageNo {
      .pages {
        li {
          button {
            color: var(--black-high, #212121);
            font-family: "Inter" !important;
            font-size: 1rem !important;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
          }
        }
        li:nth-child(1),
        li:nth-last-child(1) {
          border-radius: 4px;
          border: 1px solid var(--black-disabled-bg, #bcbcbc);
          margin: 0 10px;
        }
      }
    }
    .itemCount {
      color: var(--black-medium, #909090);
      /* font/subtitle/s2 */
      font-family: "Inter";
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      & > span {
        color: var(--black-high, #212121);
        /* font/subtitle/s2 */
        font-family: "Inter";
        font-size: 1rem;
        margin-left: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}
