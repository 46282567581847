.header-container {
  width: 100%;
  background: #000;
  color: #fff;
  .wrapper {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    .account {
      display: flex;
      position: relative;
      gap: 4px;
      width: fit-content;
      cursor: pointer;
      background: #fff;
      .dropDown {
        position: absolute;
        right: 0;
        top: 48px;
        background: #fff;
        border: 1px solid #e9e9e9;
        width: fit-content;
        min-width: 200px;
        color: #212121;
        /* font/subtitle2/semibold */
        font-size: 14px;
        font-family: "Poppins";
        font-weight: 600;
        line-height: 24px;
        z-index: 100;
        cursor: pointer;
        & > div {
          padding: 8px 16px;
          border-bottom: 1px solid #e9e9e9;
          & > div:nth-child(2) {
            font-weight: 500;
          }
        }
      }
    }
    .logout {
      font-size: 18px;
      font-family: "Poppins";
      cursor: pointer;
    }
    .logo-main {
      cursor: pointer;
    }
  }
}
