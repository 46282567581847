.back-button {
  display: flex;
  cursor: pointer;
  font-family: "Poppins";

  & > span {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
  }
}
