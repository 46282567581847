.sentInvitePage {
  margin: 0 100px;
  .homeButton {
    width: fit-content;
    margin-top: 50px;
    & > button {
      color: #3c3c3c;
      font-family: "Inter";
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  .inviteTitle {
    color: #000;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
  }
  .sent-invites {
    .applicationTableContainer {
      border-collapse: collapse;

      thead {
        border-radius: 2px;
        background: #f6faff;
        padding: 21px;
        th {
          color: var(--secondary-black, #616161);
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          padding: 21px 0;
          text-align: left;
          padding-left: 20px;
        }
        th:last-child {
          padding-right: 21px;
        }
      }

      tbody {
        tr {
          td {
            color: var(--secondary-deep-ocean-100, #2c4761);
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            padding: 20px 0;
            padding-left: 21px;

            .sendButton {
              margin-left: 25px;
              cursor: pointer;
              border-radius: 4px;
              border: 1.5px solid var(--black-high, #212121);
              color: var(--black-high, #212121);
              text-align: center;
              font-feature-settings: "clig" off, "liga" off;
              padding: 8px 16px;
              background: #fff;
              /* font/button/large/sentence */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 171.429% */
            }
          }
          .skills {
            max-width: 250px;
          }
          td:last-child {
            padding-right: 21px;
          }
        }
        tr:nth-child(odd) {
          // border: 1px solid;
          background: #fff;
        }
        tr:nth-child(even) {
          background: #f8f8f8;
        }
      }

      td.viewJd {
        cursor: pointer;
      }
    }
  }
  .filteraSection {
    max-width: 1100px;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    .selectClass {
      border-radius: 4px;
      border: 1px solid #000;
      height: 35px;
    }
    .filersButton {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      border-radius: 6px;
      background: #4318ff;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 7px 12px;
      cursor: pointer;
      & > button {
        background: inherit;
        outline: none;
        border: none;
        color: inherit;
      }
    }
  }
}

.radioFilters {
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
  .popupMainWrapper {
    padding: 40px 30px;

    width: 300px;
    float: right;
    margin-right: 50px;
    margin-top: 50px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 4px 4px 2px 0px rgba(79, 79, 79, 0.24),
      -1px 0px 2px 0px rgba(79, 79, 79, 0.31);
    .filters {
      color: #212121;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
    .status {
      color: #4f4f4f;
      font-family: "Inter";
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
    .popupMain {
      color: #4f4f4f;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    }
    .buttons {
      float: right;
      .reset {
        color: #4f4f4f;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
      .save {
        color: #4318ff;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
